<!--
 * @Description  : 关于我们
-->
<template>
  <div class="hx-main pad-news">
    <div class="pad-cover">
      <span class="text-title">
        最新咨讯
      </span>
      <img src="./../../assets/img/bg-news.jpg" alt="" class="img-cover">
    </div>
    <div class="hx-container">
      <div class="pad-news-list">
        <div class="top-area">
          <div class="pad-detail">
            <img src="./../../assets/img/img-side-news.png" alt="" class="img-cover">
          </div>
          <div class="pad-articles">
            <news-card v-for="(item, idx) in topNews"
              :key="idx"
              :detail="item">
            </news-card>
          </div>
        </div>
        <br><br>
        <hr color="#f2f2f2">
        <div class="bottom-area">
          <news-card v-for="(item, idx) in list"
            :key="idx"
            mode="image"
            class="pad-article"
            :detail="item">
          </news-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from './cpts/NewsCard.vue'
import DefaultImage from './../../assets/img/img-default-image.jpg'
export default {
  components: {
    NewsCard
  },
  data () {
    return {
      topNews: [],
      list: []
    }
  },
  mounted () {
    this.$_fakeData()
  },
  methods: {
    $_fakeData () {
      const item = {
        title: '这是一篇咨询消息的标题这是一篇咨询消息的标题',
        createdAt: '2023-04-12',
        coverImage: DefaultImage,
        desc: '这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本这是一篇咨询消息的描述文本'
      }
      this.topNews = [item, item]
      for (let i = 0; i < 12; i++) {
        this.list.push(item)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../index.less";
.pad-news {
  padding-bottom: @pm-lg * 1.5;
  .top-area {
    display: flex;
    .pad-detail {
      width: 400px;
      height: 640px;
      overflow: hidden;
      padding-left: @pm-md;
      .img-cover {
        width: auto;
        height: 100%;
      }
    }
    .pad-articles {
      flex: 1;
      padding: 0 @pm-bg;
      display: flex;
      flex-direction: column;
      .card-news {
        flex: 1;
      }
    }
  }
}
.bottom-area {
  .pad-article {
    display: inline-block;
    width: 33.3%;

  }
}
</style>
