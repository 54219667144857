<!--
 * @Description  : 联系我们
-->
<template>
  <div class="hx-main pad-contact-us">
    <div class="pad-cover">
      <span class="text-title">
        联系我们
      </span>
      <img src="./../assets/img/bg-contact-us.jpg" alt="" class="img-cover">
    </div>
    <div class="hx-container">
      <div class="hx-title">公司联系信息</div>
      <span class="hx-subtitle">CONTACT US</span>
      <div class="pad-contact-content">
        <div class="card-location" v-for="(item, idx) in locations" :key="idx">
          <div class="text-content">
            <div class="title">{{ item.name }}</div>
            <div class="row">
              <label class="label" for="">联系电话：</label>
              <a target="_blank" :href="`tel:${item.phone}`" class="text">{{ item.phone}}</a>
            </div>
            <div class="row">
              <label class="label" for="">联系人：</label>
              <a target="_blank" :href="`tel:${subItem}`" v-for="subItem in item.contact" :key="subItem" class="text">
                {{ subItem }}
              </a>
            </div>
            <div class="row">
              <label class="label" for="">电子邮箱：</label>
              <a target="_blank" :href="`mailto:${item.email}`" class="text">{{ item.email }}</a>
            </div>
            <div class="row">
              <label class="label" for="">地址：</label>
              <span class="text">{{ item.address }}</span>
            </div>
          </div>
          <div class="map-location" :id="'map-' + item.id"></div>
        </div>
      </div>
      <div class="hx-title">微信公众号</div>
      <span class="hx-subtitle">QRCODE</span>

      <div class="pad-qrcodes">
        <div class="card-qrcode">
          <img src="./../assets/img/img-qrcode.jpg" alt="" class="img-qrcode">
          <span class="text">安然安全</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      locations: [
        {
          id: 1001,
          name: '深圳总部',
          phone: '0755—28917309',
          contact: ['13923796678', '18126275079'],
          email: 'ar_xuefeng@163.com',
          address: '深圳市龙岗区龙翔大道9009号珠江广场A4栋604',
          lat: 22.729885,
          lng: 114.257086
        }
      ]
    }
  },
  mounted () {
    this.$_initMap()
  },
  methods: {
    $_initMap () {
      for (const item of this.locations) {
        const map = new window.BMapGL.Map('map-' + item.id)
        var point = new window.BMapGL.Point(item.lng, item.lat)
        map.centerAndZoom(point, 18)
        map.enableScrollWheelZoom(true)
        const marker = new window.BMapGL.Marker(point)
        map.addOverlay(marker)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/index.less";
@height-card-content: 320px;
.pad-contact-us {
  .pad-contact-content {
    display: block;
    width: 100%;
    .card-location {
      width: 100%;
      display: flex;
      background-color: #fafafa;
      transition: transform .4s;
      border-radius: 6px;
      &:hover {
        transform: scale(1.02, 1.02);
      }
      & + .card-location {
        margin-top: @pm-bg;
      }
      .text-content {
        height: @height-card-content;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-weight: 600;
          display: block;
        }
        .row {
          & + .row {
            margin-top: @pm-md;
          }
          .label {
            width: 100%;
            display: block;
            color: #666;
          }
          .text {
            font-weight: 500;
            color: #000;
            display: inline-block;
            text-decoration: none;

            & + .text {
              margin-left: @pm-md;
            }
          }
          a:hover {
            color: @color-theme;
          }
        }
      }
    }
  }
  .pad-qrcodes {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: @pm-md 0 @pm-lg * 2;
    .card-qrcode {
      display: inline-flex;
      flex-direction: column;
      padding: @pm-bg @pm-bg 0;
      border-radius: @pm-sm;
      background: linear-gradient(125deg, @color-main 0%, @color-light-blue 100%);
      .img-qrcode {
        height: 200px;
        border: 4px;
      }
      .text {
        display: block;
        width: 100%;
        line-height: 54px;
        text-align: center;
        color: white;
      }
      & + .card-qrcode {
        margin-left: @pm-lg * 2;
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .pad-contact-content {
    padding: @pm-lg @pm-bg;
    .card-location {
      padding: @pm-bg;
      .text-content {
        width: 400px;
        .title {
          margin-bottom: @pm-bg;
          font-size: 26px;
        }
        .label {
          font-size: 16px;
        }
        .text {
          font-size: 18px;
        }
      }
      .map-location {
        flex: 1;
        height: @height-card-content;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .pad-contact-content {
    padding: @pm-md 0;
    .card-location {
      padding: 0 @pm-md @pm-md;
      flex-direction: column;
      .text-content {
        width: 100%;
        margin-bottom: @pm-sm;
        .title {
          margin-bottom: @pm-sm;
          font-size: 20px;
        }
        .label {
          font-size: 14px;
        }
        .text {
          font-size: 16px;
        }
      }
    }
    .map-location {
      width: 100%;
      height: @height-card-content - 100px;
    }
  }
}
</style>
