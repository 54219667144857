<!--
 * @Description  : 咨询卡片
-->

<template>
  <div :class="['card-news', mode]">
    <img class="img-cover" :src="detail.coverImage" v-if="mode === 'image'" alt="">
    <span class="title">{{ detail.title }}</span>
    <span class="subtitle">{{ detail.createdAt }}</span>
    <span class="desc">{{ detail.desc }}</span>
    <span class="tag">新闻咨询</span>
    <div class="pad-mask">
      前往查看 →
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'text',
      validator (data) {
        return ['text', 'image'].includes(data)
      }
    },
    detail: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../../index.less";
.card-news {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .pad-mask {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.5);
    color: white;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 16px;
    padding: @pm-bg;
    top: 0;
    left: 0;
    opacity: .0;
    visibility: hidden;
    transition: opacity .3s;
  }
  &:hover {
    transform: scale(1.02, 1.02);
    .pad-mask { opacity: 1.0; visibility: visible; }
  }
  &.text {
    padding: @pm-bg @pm-md;
  }
  & + .card-news.text {
    &::before {
      content: "";
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
      background-color: @color-gray-border;
      display: block;
      transform: scaleY(.4);
      position: absolute;
    }
  }
  &.image {
    padding: @pm-md;
    .img-cover {
      margin-bottom: @pm-bg;
      height: 200px;
    }
  }
  .title {
    font-size: 20px;
    line-height: 2;
    font-weight: 600;
    display: block;
    color: #000;
    margin-bottom: @pm-sm;
  }
  .subtitle {
    color: @color-heavy;
    display: block;
    font-size: 14px;
    margin-bottom: @pm-md;
  }
  .desc {
    display: block;
    color: #888;
    line-height: 1.8;
    font-size: 16px;
    word-break: break-all;
  }
  .tag {
    font-size: 14px;
    padding: 3px 8px;
    color: #aaa;
    display: inline-block;
    border-radius: 4px;
    margin-top: @pm-md;
    border: 1px solid @color-gray-border;
  }
}
</style>
